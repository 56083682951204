import React, { useEffect, useState } from "react";
import * as S from "./estilos";

import useData from "../../Utils/useData";

import RiscoItem from "../../Images/RiscoItem.png";
import ImgRiskRodape from "../../Images/ImgRiskRodape.png";
import Logo from "../../Images/Logo.png";
import ButtonFooter from "../../Images/ButtonFooter.png";
import IconFooter1 from "../../Images/IconFooter1.png";
import IconFooter2 from "../../Images/IconFooter2.png";
import IconFooter3 from "../../Images/IconFooter3.png";
import IconFooter4 from "../../Images/IconFooter4.png";

function Footer() {
  const [data, setData] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [load, setLoad] = useState(false);

  const MandaFace = () => {
    window.open(
      "https://www.facebook.com/publistand.empreendimentos/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0",
      "_blank",
    );
  };

  const MandaInsta = () => {
    window.open("https://www.instagram.com/publistand/", "_blank");
  };

  const MandaLinkedin = () => {
    window.open("https://www.linkedin.com/company/publistand-prom-empr-ltda-/posts/?feedView=all", "_blank");
  };

  const MandaYoutube = () => {
    window.open("https://www.youtube.com/channel/UCAgVkkVAuDyPoKunZIeJGCQ", "_blank");
  };

  const MandaPdf = () => {
    window.open("https://publistand.com.br/Apresentaçao_Publistand_2023_50A.pdf", "_blank");
  };

  const loadData = () => {
    useData()
      .getData("parceiros.php")
      .then((response: any) => {
        // alert(response);
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });

    useData()
      .getData("arquitetos.php")
      .then((response: any) => {
        // alert(response);
        setData2(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <S.OutPartners>
        <S.TopPartners>
          <S.OutRisk>
            <img src={RiscoItem} />
          </S.OutRisk>
          <S.TextPartners1>PARCEIROS</S.TextPartners1>
        </S.TopPartners>
        <S.ContentPartners>
          <S.OutAgency>
            <S.TextPartners2>AGÊNCIAS</S.TextPartners2>
            <S.ItensPartners>
              {data &&
                data.map((data: any, index: React.Key | null | undefined) => (
                  <S.ItemPartner key={index}>{data.c_titulo}</S.ItemPartner>
                ))}
            </S.ItensPartners>
          </S.OutAgency>
          <S.OutAgency>
            <S.TextPartners2>ARQUITETOS</S.TextPartners2>
            <S.ItensPartners>
              {data2 &&
                data2.map((data: any, index2: React.Key | null | undefined) => (
                  <S.ItemPartner key={index2}>{data.c_titulo}</S.ItemPartner>
                ))}
            </S.ItensPartners>
          </S.OutAgency>
        </S.ContentPartners>
      </S.OutPartners>

      <S.BgFooter>
        <S.OutFooter>
          <S.OutRiskFooter>
            <img src={ImgRiskRodape} />
          </S.OutRiskFooter>
          <S.RightFooter>
            <S.ContentFooter>
              <S.LogoFooter>
                <img src={Logo} />
              </S.LogoFooter>
              <S.TextTel>
                <span>55 11</span> 2776 9691 <span>|</span> 2776 9692
              </S.TextTel>
              <S.OutIcons>
                <S.Icon onClick={() => MandaInsta()}>
                  <img src={IconFooter1} />
                </S.Icon>
                <S.Icon onClick={() => MandaFace()}>
                  <img src={IconFooter2} />
                </S.Icon>
                <S.Icon onClick={() => MandaYoutube()}>
                  <img src={IconFooter3} />
                </S.Icon>
                <S.Icon onClick={() => MandaLinkedin()}>
                  <img src={IconFooter4} />
                </S.Icon>
              </S.OutIcons>
              <S.OutButtonFooter onClick={() => MandaPdf()}>
                <img src={ButtonFooter} />
              </S.OutButtonFooter>
            </S.ContentFooter>
          </S.RightFooter>
        </S.OutFooter>
      </S.BgFooter>
    </>
  );
}

export default Footer;
